exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dolny-slask-js": () => import("./../../../src/pages/dolny-slask.js" /* webpackChunkName: "component---src-pages-dolny-slask-js" */),
  "component---src-pages-dzialalnosc-js": () => import("./../../../src/pages/dzialalnosc.js" /* webpackChunkName: "component---src-pages-dzialalnosc-js" */),
  "component---src-pages-filmowa-roza-wiatrow-js": () => import("./../../../src/pages/filmowa-roza-wiatrow.js" /* webpackChunkName: "component---src-pages-filmowa-roza-wiatrow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kobieta-niejedno-ma-imie-js": () => import("./../../../src/pages/kobieta-niejedno-ma-imie.js" /* webpackChunkName: "component---src-pages-kobieta-niejedno-ma-imie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-materialy-js": () => import("./../../../src/pages/materialy.js" /* webpackChunkName: "component---src-pages-materialy-js" */),
  "component---src-pages-muzyczny-wroclaw-js": () => import("./../../../src/pages/muzyczny-wroclaw.js" /* webpackChunkName: "component---src-pages-muzyczny-wroclaw-js" */),
  "component---src-pages-o-regionie-js": () => import("./../../../src/pages/o-regionie.js" /* webpackChunkName: "component---src-pages-o-regionie-js" */),
  "component---src-pages-od-slowa-do-slowa-js": () => import("./../../../src/pages/od-slowa-do-slowa.js" /* webpackChunkName: "component---src-pages-od-slowa-do-slowa-js" */),
  "component---src-pages-odkryj-to-sam-js": () => import("./../../../src/pages/odkryj-to-sam.js" /* webpackChunkName: "component---src-pages-odkryj-to-sam-js" */),
  "component---src-pages-odkrywamy-wroclaw-js": () => import("./../../../src/pages/odkrywamy-wroclaw.js" /* webpackChunkName: "component---src-pages-odkrywamy-wroclaw-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-opowiem-ci-o-wroclawiu-js": () => import("./../../../src/pages/opowiem-ci-o-wroclawiu.js" /* webpackChunkName: "component---src-pages-opowiem-ci-o-wroclawiu-js" */),
  "component---src-pages-podroz-do-przeszlosci-js": () => import("./../../../src/pages/podroz-do-przeszlosci.js" /* webpackChunkName: "component---src-pages-podroz-do-przeszlosci-js" */),
  "component---src-pages-poznajemy-wroclaw-js": () => import("./../../../src/pages/poznajemy-wroclaw.js" /* webpackChunkName: "component---src-pages-poznajemy-wroclaw-js" */),
  "component---src-pages-projekty-edukacyjne-js": () => import("./../../../src/pages/projekty-edukacyjne.js" /* webpackChunkName: "component---src-pages-projekty-edukacyjne-js" */),
  "component---src-pages-przewodnicy-js": () => import("./../../../src/pages/przewodnicy.js" /* webpackChunkName: "component---src-pages-przewodnicy-js" */),
  "component---src-pages-publikacje-js": () => import("./../../../src/pages/publikacje.js" /* webpackChunkName: "component---src-pages-publikacje-js" */),
  "component---src-pages-questy-js": () => import("./../../../src/pages/questy.js" /* webpackChunkName: "component---src-pages-questy-js" */),
  "component---src-pages-stowarzyszenie-js": () => import("./../../../src/pages/stowarzyszenie.js" /* webpackChunkName: "component---src-pages-stowarzyszenie-js" */),
  "component---src-pages-strona-po-stronie-js": () => import("./../../../src/pages/strona-po-stronie.js" /* webpackChunkName: "component---src-pages-strona-po-stronie-js" */),
  "component---src-pages-szkola-w-miescie-js": () => import("./../../../src/pages/szkola-w-miescie.js" /* webpackChunkName: "component---src-pages-szkola-w-miescie-js" */),
  "component---src-pages-to-dopiero-historia-js": () => import("./../../../src/pages/to-dopiero-historia.js" /* webpackChunkName: "component---src-pages-to-dopiero-historia-js" */),
  "component---src-pages-trasy-wycieczek-js": () => import("./../../../src/pages/trasy-wycieczek.js" /* webpackChunkName: "component---src-pages-trasy-wycieczek-js" */),
  "component---src-pages-tropem-naszego-dziedzictwa-js": () => import("./../../../src/pages/tropem-naszego-dziedzictwa.js" /* webpackChunkName: "component---src-pages-tropem-naszego-dziedzictwa-js" */),
  "component---src-pages-wedrownik-po-powiecie-wroclawskim-js": () => import("./../../../src/pages/wedrownik-po-powiecie-wroclawskim.js" /* webpackChunkName: "component---src-pages-wedrownik-po-powiecie-wroclawskim-js" */),
  "component---src-pages-wroclaw-js": () => import("./../../../src/pages/wroclaw.js" /* webpackChunkName: "component---src-pages-wroclaw-js" */),
  "component---src-pages-wroclaw-natura-sztuka-architektura-js": () => import("./../../../src/pages/wroclaw-natura-sztuka-architektura.js" /* webpackChunkName: "component---src-pages-wroclaw-natura-sztuka-architektura-js" */),
  "component---src-pages-wycieczki-po-dolnym-slasku-i-nie-tylko-js": () => import("./../../../src/pages/wycieczki-po-dolnym-slasku-i-nie-tylko.js" /* webpackChunkName: "component---src-pages-wycieczki-po-dolnym-slasku-i-nie-tylko-js" */),
  "component---src-pages-z-biegiem-rzeki-js": () => import("./../../../src/pages/z-biegiem-rzeki.js" /* webpackChunkName: "component---src-pages-z-biegiem-rzeki-js" */),
  "component---src-pages-z-wroclawiem-w-tle-js": () => import("./../../../src/pages/z-wroclawiem-w-tle.js" /* webpackChunkName: "component---src-pages-z-wroclawiem-w-tle-js" */)
}

